import React, { lazy } from 'react';
import './OpenSocial.sass';
import i18n from '../../../../../i18n';
import Hash from './../../../../../resources/icons/openSocial/Hash.svg';
import Open from './../../../../../resources/icons/openSocial/Open.svg';
import Social from './../../../../../resources/icons/openSocial/Social.svg';
import opensocialSmall from './../../../../../resources/icons/openSocial/opensocialSmall.svg';
import Config from '../../../../../base/config/Config';
import Cookies from 'js-cookie';
const Button = lazy(() => import('../../../../component/button/Button'));
const Title = lazy(() => import('../../../../component/title/Title'));

function OpenSocial() {
    var currentLanguage = Cookies.get('currentLanguage');
    return (
        <div className="OpenSocialArea">
            <Title size="Big" type="Primary" text={i18n.t('Soon').toUpperCase()} />
            <div className='OpenSocialImagesArea'>
                <img className='OpenSocialSmallImage' src={opensocialSmall} />
                <img className='OpenSocialImage' src={Hash} />
                <div className='OpenSocialImages'>
                    <img className='OpenSocialImage' src={Open} />
                    <img className='OpenSocialImage' src={Social} />
                </div>
            </div>
            <Button to={`${Config.blog}${currentLanguage}/manifesto-swonkie-open-social`} size="Medium" type="Tertiary" text={i18n.t('read more about it')} />
        </div>
    );
}

export default OpenSocial;
