import React from 'react';
import './SmallText.sass';
import PropTypes from 'prop-types';

const SmallText = React.memo(props => {
    var { text, type, size, font, html, color } = props;
    var classValue = 'SmallText ' + size + ' ' + type + ' ' + font + ' ' + color;

    var valueHtml = <span className={classValue} ><span dangerouslySetInnerHTML={{ __html: html }}></span> {text} </span>;

    return valueHtml;
});

SmallText.propTypes = {
    text: PropTypes.any.isRequired,
    size: PropTypes.oneOf(['Titan', 'colossal', 'Giant', 'Bigger', 'Big', 'Medium', 'Small']),
    type: PropTypes.oneOf(['Primary', 'Secundary', 'Tertiary', 'Fourth', 'Fifth', 'Six', 'Seventh', 'Eight', 'Ninth', 'Gradient', 'Error', 'Disabled']),
    font: PropTypes.oneOf(['OpenSans', 'OpenSansItalic', 'OpenSansBold', 'Roboto', 'RobotoBold', 'SofiaProBold', 'SofiaProRegular', 'SofiaProItalic', 'SofiaProLight', 'SofiaProLightItalic', 'Montserrat', 'HalyardMicroBold']),
    color: PropTypes.any
};

SmallText.defaultProps = {
    size: 'big',
    type: 'Secundary',
    font: 'OpenSans',
    color: ''
};

export default SmallText;
