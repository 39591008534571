import React from 'react';
import './Message.sass';
import PropTypes from 'prop-types';

const Message = React.memo(props => {
    var { text, type, size, html } = props;
    var classValue = 'Message ' + size + ' ' + type;

    var valueHtml = <span className={classValue} > <span dangerouslySetInnerHTML={{__html: html}}></span> {text} </span>;

    return valueHtml;
});

Message.propTypes = {
    text: PropTypes.string.isRequired,
    size: PropTypes.oneOf(['Giant' ,'Bigger' ,'Big', 'Medium', 'Small']),
    type: PropTypes.oneOf(['Primary', 'PrimaryBold', 'Secundary', 'Tertiary', 'Fourth', 'Agreement', 'Witness', 'WitnessSecundary' , 'PlanBox'])
};

Message.defaultProps = {
    size: 'big',
    type: 'Primary'
};

export default Message;
