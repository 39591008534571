import React, { Component } from 'react';
import './Dropdown.sass';
import PropTypes from 'prop-types';
import Button from '../button/Button';

class Dropdown extends Component {
    constructor(props) {
        super(props);

        this.state = { collapse: false };
        this.selectedArea = React.createRef();
        this.wrapperRef = React.createRef();
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            if (this.state.collapse === true) {
                this.setState({ collapse: false });
            }
        }
    }

    toggle = () => {
        this.setState({ collapse: !this.state.collapse });
        this.selectedArea.current.classList.toggle('selected');
    }

    toggleIn = () => {
        this.setState({ collapse: true });
        this.selectedArea.current.classList.toggle('selected');
    }

    toggleOut = () => {
        this.setState({ collapse: false });
        this.selectedArea.current.classList.toggle('selected');
    }


    render() {

        const text = this.props.text || '';
        const children = this.props.children || '';
        var buttonType = this.props.buttonType || 'TransparentTertiary';
        const type = this.props.type || 'Primary';

        var classValue = 'Dropdown ' + type;

        var toggleIn = this.toggleIn;
        var toggleOut = this.toggleOut;

        if (type === 'Secundary') {
            toggleIn = null;
            toggleOut = null;
            if (this.state.collapse === true) {
                buttonType = 'SofiaProBlackSmallActive';
            }
        }

        var html = <div onMouseOver={toggleIn} onMouseLeave={toggleOut} className={classValue} ref={this.setWrapperRef} >
            <div className="DropdownButtonArea" ref={this.selectedArea} onClick={this.toggle}>
                <div className="DropdownButton" >
                    <Button size="Small" type={buttonType} text={text} />
                </div>
                <div className={this.state.collapse ? 'dropdownButtonChervonUp' : 'dropdownButtonChervonDown'} />
            </div>
            <div className={this.state.collapse ? 'fadeIn' : 'fadeOut'}>
                <div className={this.state.collapse ? 'DropdownLine' : ''} />
                <div className={this.state.collapse ? 'DropdownContentIn' : 'DropdownContentOut'}>
                    {children}
                </div>
            </div>
        </div>;

        return html;
    }
}

Dropdown.propTypes = {
    type: PropTypes.oneOf(['Primary', 'Secundary']),
    text: PropTypes.string,
    children: PropTypes.element

};

Dropdown.defaultProps = {
    type: 'Primary',
    text: 'Text',
    children: null,
    buttonType: 'TransparentTertiary'
};


export default Dropdown;
