import React from 'react';
import { Link } from 'react-router-dom';
import './Button.sass';
import PropTypes from 'prop-types';

const Button = React.memo(props => {
    var { size, text, to, onClick, noOverflow, type } = props;

    var checkLink = (html, to) => {
        if (to !== null) {
            if (to.substr(0, 1) === '/')
                return <Link to={to}>{html}</Link>;
            else if (to.substr(0, 4) === 'http')
                return <a href={to} target="_blank" rel="noopener noreferrer">{html}</a>;
        } else
            return html;
    };

    var classValue = 'Button ' + size + ' ' + type + (noOverflow ? ' Overflow' : '');

    var html = <div className={classValue} onClick={onClick}> {text} </div>;

    html = checkLink(html, to);

    return html;
});

Button.propTypes = {
    text: PropTypes.string.isRequired,
    to: PropTypes.string,
    onClick: PropTypes.func,
    type: PropTypes.oneOf(['Primary', 'Secundary', 'Tertiary', 'Transparent', 'TransparentSecundary', 'TransparentTertiary', 'Transparentfourth', 'TransparentFifth', 'TransparentHover', 'UnderlineTransparent', 'UnderlineTransparentSecundary', 'PrimaryPricingButton', 'SecundaryPricingButton', 'Academy', 'SofiaProBlack', 'SofiaProBlackActive', 'SofiaProBlackSmall', 'SofiaProBlackSmallActive', 'MonsterratBlack', 'SmallBlue', 'Footer', 'typeClientButton', 'typeClientButtonActive']),
    size: PropTypes.oneOf(['Giant', 'Big', 'Medium', 'Small'])
};

Button.defaultProps = {
    to: null,
    onClick: function () { },
    type: 'Primary',
    size: 'Big'
};

export default Button;
