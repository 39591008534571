import { init as initApm } from '@elastic/apm-rum';

//Docs: https://www.elastic.co/guide/en/apm/agent/rum-js/master/configuration.html

var apm = initApm({
    serviceName: process.env.REACT_APP_ELASTIC_APM_SERVICE_NAME,
    serviceVersion: '0.90',
    serverUrl: process.env.REACT_APP_ELASTIC_APM_HOST,
    environment: process.env.REACT_APP_ENVIRONMENT,
    active: (process.env.REACT_APP_ELASTIC_APM_HOST.trim() !== ''),
    logLevel: 'trace',
});

export default apm;
