import './App.sass';
import React, { Component, Suspense } from 'react';
import i18n from '../../i18n';
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Utils from '../Utils';
import Config from '../config/Config';
import ScrollToTop from '../scrollToTop';
import remove from '../../resources/icons/removeGrey.svg';
import LandingPage from './../../common/container/landingPage/LandingPage';
import { posthog } from 'posthog-js';
import { ProductFruits } from 'react-product-fruits';

const Button = React.lazy(() => import('../../common/component/button/Button'));
const SmallText = React.lazy(() => import('../../common/component/smallText/Smalltext'));
const ChatArea = React.lazy(() => import('../../common/component/chatArea/ChatArea'));
const CategoryPage = React.lazy(() => import('./../../common/container/categoryPage/CategoryPage'));
const PlanPage = React.lazy(() => import('./../../common/container/PlanPage/PlanPage'));
const GetInTouchPage = React.lazy(() => import('./../../common/container/getInTouchPage/GetInTouchPage'));
const AboutUsPage = React.lazy(() => import('./../../common/container/aboutUsPage/AboutUsPage'));
const PressRoomPage = React.lazy(() => import('./../../common/container/pressRoomPage/PressRoomPage'));
const OpenPositionPage = React.lazy(() => import('./../../common/container/openPositionsPage/OpenPositionPage'));
const DetailPositionPage = React.lazy(() => import('./../../common/container/detailPositionPage/DetailPositionPage'));
const LogoutPage = React.lazy(() => import('./../../common/container/logoutPage/LogoutPage'));
const TermsConditionPage = React.lazy(() => import('./../../common/container/TermsConditionPage/TermsConditionPage'));
const CalendarPage = React.lazy(() => import('./../../common/container/calendarPage/CalendarPage'));
const Portugal2020 = React.lazy(() => import('./../../common/container/portugal2020/Portugal2020'));
const CalculatorsPage = React.lazy(() => import('./../../common/container/calculatorsPage/CalculatorsPage'));
const DetailedCalculatorPage = React.lazy(() => import('./../../common/container/calculatorsPage/subpages/DetailedCalculatorPage'));
const CategoryCalculator = React.lazy(() => import('./../../common/container/calculatorsPage/subpages/CategoryCalculator'));
const BadgePage = React.lazy(() => import('./../../common/container/badgePage/BadgePage'));

class App extends Component {
    currentLanguage = ''

    constructor(props) {
        super(props);

        /*
        if (this.currentLanguage !== i18n.language) {
            this.currentLanguage = i18n.language
            window.history.replaceState({}, document.title, `/${this.currentLanguage}`);
        }


        if (window.location.pathname.indexOf("/terms&conditions") !== -1) {
            window.history.replaceState({}, document.title, `/${this.currentLanguage}/terms-conditions`);
        } else if (window.location.pathname.indexOf("/privacy") !== -1) {
            window.history.replaceState({}, document.title, `/${this.currentLanguage}/privacy-policy`);
        }
        */

        posthog.onFeatureFlags(() => {
            if (posthog.isFeatureEnabled('enableSessionRecording')) {
                posthog.startSessionRecording();
            }
        });

        this.state = {
            promoCode: Cookies.get('promoCode'),
            hideCookieBanner: Cookies.get('hideCookieBanner')
        };
        this.saveUtmCookie();
    }

    saveUtmCookie() {
        var query = window.location.search.substring(1);
        var vars = query.split('&');
        var utms = vars.filter(function (v) {
            return v !== '';
        }).map(function (item) {
            return item.split('=');
        }).filter((v) => {
            return typeof v[0] == 'string' && v[0].startsWith('utm');
        }).reduce(function (utms, item) {
            utms[item[0]] = item[1];
            return utms;
        }, {});
        if (Object.keys(utms).length > 0) {
            Utils.setCookie('utms', JSON.stringify(utms), null, Config.cookieHost);
            Utils.setCookie('utmsCookie', JSON.stringify(utms), null, Config.cookieHost);
        }
    }

    render() {
        var location = window.location;
        var hideCookieBanner = this.state.hideCookieBanner;

        var browserLanguage = window.navigator.language.toLocaleLowerCase();

        if (browserLanguage.includes('pt')) {
            if (!browserLanguage.includes('br'))
                browserLanguage = 'pt';
        }
        else if (browserLanguage.includes('es')) {
            browserLanguage = 'es';
        }
        else if (!Utils.isset(Config.langFiles[`${browserLanguage}`])) {
            browserLanguage = 'en';
        }

        this.currentLanguage = (location.pathname.split('/')[1] || '');

        var parentLanguage = this.currentLanguage.split('-')[0];
        var childLang = this.currentLanguage.split('-')[1];

        this.currentLanguage = parentLanguage;

        if (Utils.isset(childLang)) {
            childLang = childLang.toUpperCase();
            this.currentLanguage += '-' + childLang;
        }

        if (!Utils.isset(Config.langFiles[this.currentLanguage])) {
            var auxLang = this.currentLanguage.split('-')[0];
            if (!Utils.isset(Config.langFiles[`${auxLang}`])) {
                window.location = `/${browserLanguage}` + (location.pathname + location.search + location.hash).trim();
            }
            else {
                window.location = `/${auxLang}` + (location.pathname + location.search + location.hash).trim();
            }
        }
        Cookies.set('currentLanguage', this.currentLanguage);

        var metaArray = [
            {
                language: 'English',
                lang: 'en',
                title: 'Save Time Managing Social Media | Swonkie',
                description: 'Swonkie is a social network management platform for digital marketing agencies. Try it free for 14 days!',
            },
            {
                language: 'Espanol',
                lang: 'es',
                title: 'Gana Tiempo en la Gestion de las Redes Sociales | Swonkie',
                description: 'Swonkie es una plataforma de gestión de redes sociales para agencias de marketing digital. ¡Pruébalo gratis durante 14 días!',
            },
            {
                language: 'Português',
                lang: 'pt',
                title: 'Swonkie - Plataforma de Gestão de Redes Sociais para Agências de Marketing Digital',
                description: 'O Swonkie é uma plataforma de gerenciamento de redes sociais para agências de marketing digital. Experimente-o gratuitamente por 14 dias!',
            },
            {
                language: 'Português do Brasil',
                lang: 'pt-br',
                title: 'Economize Tempo na Gestão de Mídias Sociais | Swonkie',
                description: 'O Swonkie é uma plataforma de gerenciamento de redes sociais para agências de marketing digital. Experimente-o gratuitamente por 14 dias!',
            },
            {
                language: 'Francês',
                lang: 'fr',
                title: 'Gagnez du temps en gérant les réseaux sociaux | Swonkie',
                description: 'Swonkie est une plateforme de gestion de réseaux sociaux pour les agences de marketing numérique. Essayez-le gratuitement pendant 14 jours!',
            }
        ];

        var metaText = metaArray[0];

        metaArray.forEach((meta) => {
            if (meta.lang === this.currentLanguage) {
                metaText = meta;
            }
        });

        var hasPromoCode = false;

        var promoText = this.state.promoCode;

        if (typeof promoText !== 'undefined') {
            hasPromoCode = true;
            var discountText = <>{i18n.t('Discount code applied')} <span>{promoText}</span></>;
        } else {
            hasPromoCode = false;
        }

        var cookieMessage = i18n.t('Hi there, we use cookies to offer you a better browsing experience and to analyze site traffic. By continuing to use our website, you consent to the use of these cookies. |Learn More|');
        var startText;
        var endText;
        var text_to_get;

        startText = cookieMessage.indexOf('|') + 1;
        endText = cookieMessage.indexOf('|', startText);
        text_to_get = cookieMessage.substring(startText, endText);

        cookieMessage = cookieMessage.replace(`|${text_to_get}|`, `<a href = "${Config.host}${this.currentLanguage}/privacy-policy#7" target = "_blank"> <span id = "security" class = "Blue" >${text_to_get}</span> </a>`);
        var path = window.location.pathname;

        var externalPages = [
            {
                path: `/${this.currentLanguage}/badge/verification/:id`,
                component: BadgePage,
                pageVerification: '/badge/verification'
            }
        ];

        var externalId = externalPages.findIndex((externalPage) => path.includes(externalPage.pageVerification));

        return (
            <Suspense fallback={null}>
                {externalId >= 0 ? <Router>
                    <Switch>
                        <Route exact path={externalPages[`${externalId}`].path} component={externalPages[`${externalId}`].component} />;
                    </Switch>
                </Router>
                    :
                    <div className="App">
                        <Helmet htmlAttributes={{ lang: i18n.language }}>
                            <title>{metaText.title}</title>
                            <meta name="description" content={metaText.description} />
                            <meta name="language" content={metaText.language} />
                            <meta name="lang" content={metaText.lang} />
                            <meta property="og:title" content={metaText.title} />
                            <meta property="og:description" content={metaText.description} />
                            <meta name="twitter:title" content={metaText.title} />
                            <meta name="twitter:description" content={metaText.description} />
                            <meta name="thumbnail" content={Config.base + 'metaImage.webp'} />
                            <meta property="og:image" content={Config.base + 'metaImage.webp'} />
                            <meta name="twitter:image" content={Config.base + 'metaImage.webp'} />
                            <link rel="canonical" href={Config.host + this.currentLanguage} />
                            <meta property="og:url" content={Config.host + this.currentLanguage} />
                            <meta name="twitter:url" content={Config.host + this.currentLanguage} />
                        </Helmet>
                        <Router>
                            <ScrollToTop />
                            <Switch>
                                <Route exact path={`/${this.currentLanguage}`} component={LandingPage} />
                                <Route exact path={`/${this.currentLanguage}/promo/:code`} render={(props) =>
                                    <LandingPage {...props} promoCodeCallback={(promoCode) => {
                                        this.setState({ promoCode: promoCode });
                                    }} />} />
                                <Route exact path={`/${this.currentLanguage}/solutions/:category`} component={CategoryPage} />
                                <Route exact path={`/${this.currentLanguage}/terms-conditions`} component={TermsConditionPage} />
                                <Route exact path={`/${this.currentLanguage}/privacy-policy`} component={TermsConditionPage} />
                                <Route exact path={`/${this.currentLanguage}/gdpr`} component={TermsConditionPage} />
                                <Route exact path={`/${this.currentLanguage}/about`} component={AboutUsPage} />
                                <Route exact path={`/${this.currentLanguage}/pricing`} component={PlanPage} />
                                <Route exact path={`/${this.currentLanguage}/contact`} component={GetInTouchPage} />
                                <Route exact path={`/${this.currentLanguage}/press-room`} component={PressRoomPage} />
                                <Route exact path={`/${this.currentLanguage}/logout`} component={LogoutPage} />
                                <Route exact path={`/${this.currentLanguage}/careers`} component={OpenPositionPage} />
                                <Route exact path={`/${this.currentLanguage}/careers/:position/:id`} component={DetailPositionPage} />
                                <Route exact path={`/${this.currentLanguage}/holiday-calendar`} component={CalendarPage} />
                                <Route exact path={`/${this.currentLanguage}/calculator`} component={CalculatorsPage} />
                                <Route exact path={`/${this.currentLanguage}/calculator/:category`} component={DetailedCalculatorPage} />
                                <Route exact path={`/${this.currentLanguage}/calculator/:category/:page`} component={CategoryCalculator} />
                                <Route exact path={`/${this.currentLanguage}/2020`} component={Portugal2020} />
                                <Route exact path="*" component={LandingPage} />
                            </Switch>
                            <div className='BannerArea'>
                                {!hideCookieBanner && <div className="CookieArea">
                                    <div className="CookieTextArea">
                                        <SmallText size="Medium" type="Seventh" font="OpenSans" html={`${cookieMessage}`} />
                                    </div>
                                    <div className='CookieButtonArea'>
                                        <Button onClick={() => this.setState({ hideCookieBanner: true }, () => { Cookies.set('hideCookieBanner', true); })} size='Small' type='SecundaryPricingButton' text={i18n.t('I understand')} />
                                        <img className='CookieRemove' src={remove} alt='' onClick={() => this.setState({ hideCookieBanner: true })} />
                                    </div>
                                </div>}
                                {hasPromoCode ?
                                    <div className="PromoArea">
                                        <div className="PromoTextArea">
                                            <SmallText size="Big" type="Primary" font="SofiaProRegular" text={discountText} />
                                        </div>
                                        <div className="PromoButtonArea">
                                            <Link to={`/${this.currentLanguage}/pricing`}><Button type="Secundary" size="Small" text={i18n.t('See Discounted Plans')} /></Link>
                                        </div>
                                    </div> : null}
                            </div>
                            <ChatArea />
                            <ProductFruits workspaceCode={Config.productFruits} language={this.currentLanguage} user={{ username: 'visitor' }} />
                        </Router>
                    </div>}
            </Suspense>
        );
    }
}

export default App;
