class Config {
    static base = process.env.REACT_APP_BASE_PATH;

    static productFruits = process.env.REACT_APP_PRODUCT_FRUITS;

    static host = process.env.REACT_APP_HOST;
    static appHost = process.env.REACT_APP_APP_HOST;
    static lpHost = process.env.REACT_APP_LP_HOST;
    static storage = process.env.REACT_APP_STORAGE_HOST;
    static cookieHost = process.env.REACT_APP_COOKIE_HOST;

    static support2020 = this.host + '2020';
    static pdf2020 = this.host + 'Docs/2020.pdf?v=2';

    static oldBackendHost = process.env.REACT_APP_BACKEND_OLD_HOST;
    static backendHost = process.env.REACT_APP_BACKEND_HOST;

    static email = 'sayhello@swonkie.com';
    static location = 'Rua do Progresso nº 140 Vila Nova de Famalicão, Portugal'

    static blog = process.env.REACT_APP_BLOG_HOST;
    static blogRSS = process.env.REACT_APP_BLOG_RSS_HOST;

    static captchaV2Key = process.env.REACT_APP_CAPTCHA_V2_KEY;
    static captchaV3Key = process.env.REACT_APP_CAPTCHA_V3_KEY;

    static mainLang = 'en';
    static langFiles = {
        en: require('../../resources/locales/en.json'),
        pt: require('../../resources/locales/pt.json'),
        es: require('../../resources/locales/es.json'),
        'pt-BR': require('../../resources/locales/pt-br.json'),
        fr: require('../../resources/locales/fr.json')
    };
}

export default Config;
