import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import Config from './base/config/Config';

const files = Config.langFiles;

var resources = {};

for (var lang in files) {
    resources[`${lang}`] = {
        translation: files[`${lang}`]
    };
}

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        debug: false,
        resources,
        detection: {
            order: ['path', 'cookie', 'navigator', 'localStorage', 'subdomain', 'queryString', 'htmlTag'],
            lookupFromPathIndex: 0
        },
        fallbackLng: Config.mainLang,
        saveMissing: true,
        keySeparator: false,
        interpolation: { escapeValue: false },
        react: { useSuspense: false },
        i18n: { seo: true, },
        nsSeparator: '::'
    });

export default i18n;
