import React from 'react';
import './Title.sass';
import PropTypes from 'prop-types';


const Title = React.memo(props => {
    var { text, type, size, html, color } = props;
    var classValue = 'Title ' + size + ' ' + type + ' ' + color;

    var valueHtml = <span className={classValue} ><span dangerouslySetInnerHTML={{ __html: html }}></span> {text} </span>;

    return valueHtml;
});

Title.propTypes = {
    text: PropTypes.string.isRequired,
    size: PropTypes.oneOf(['Giant', 'Bigger', 'Big', 'Medium', 'Small']),
    type: PropTypes.oneOf(['Primary', 'PrimaryRegular', 'Secundary', 'SecundaryRegular', 'Tertiary', 'Fourth', 'Academy', 'Disabled']),
    color: PropTypes.any
};

Title.defaultProps = {
    size: 'Big',
    type: 'Primary',
    html: '',
    color: ''
};

export default Title;
