import React from 'react';
import axios from 'axios';
import Config from './config/Config';
import Cookies from 'js-cookie';
import i18n from '../i18n';
class Utils {

    static clone(_class, recursive) {
        var obj = '';
        recursive = this.isset(recursive) ? recursive : true;


        if (typeof _class == 'object' && _class != null) {
            if (Array.isArray(_class)) {
                obj = _class.slice(0);
            } else {
                obj = Object.assign({}, _class);
            }
        } else {
            obj = _class;
        }

        if (!recursive) {
            return obj;
        }

        for (let value of Object.values(obj)) {
            if (typeof value == 'object' && value != null) {
                value = this.clone(value);
            }
        }

        return obj;
    }

    static checkAvailability(array, value, setDefault = false) {
        if (array.indexOf(value) < 0) {
            if (setDefault)
                return array[0];
            else
                return '';
        } else
            return value;
    }

    static Request(data, callback) {

        const method = data['method'] || '';
        const url = data['url'] || '';
        const param = data['data'] || '';
        const headers = data['headers'] || '';

        if (method.toLowerCase() === 'post') {
            data = {
                url: url,
                method: method.toLowerCase(),
                contentType: 'application/json',
                data: param
            };

            if (headers) {
                data.headers = headers;
            }

            axios(data).then(function (response) {
                callback(response.data);
            })
                .catch(function (e) {
                    console.log(e);
                });
        } else if (method.toLowerCase() === 'get') {
            axios.get(url)
                .then((response) => {
                    // console.log(response.data);
                    callback(response.data);
                    return (response.data);
                }).catch(function (e) {
                    console.log(e);
                });
        } else if (method.toLowerCase() === 'put') {
            data = {
                url: url,
                method: method.toLowerCase(),
                contentType: 'application/json',
                data: param
            };

            if (headers) {
                data.headers = headers;
            }

            axios(data).then(function (response) {
                callback(response.data);
            })
                .catch(function (e) {
                    console.log(e);
                });
        } else {
            console.log('Undefined');
        }
    }

    static calculatePlanPrice(planPricing, isYearly) {
        isYearly = isYearly || false;
        var monthNumber = 1;
        var monthDiscount = 0;

        if (isYearly) {
            monthNumber = 12;
            monthDiscount = 15;
        }

        var basePrice = planPricing.price;
        var priceWithMonthDiscount = parseFloat(Math.round(((((basePrice * monthNumber) * (100 - monthDiscount)) / 100)) * 100) / 100).toFixed(2);

        var discountAmmount = 0;
        if (typeof planPricing.discount != 'undefined') {
            var discount = planPricing.discount;
            if (discount['type'] === 'percentage') {
                discountAmmount = (priceWithMonthDiscount * (discount['value'] / 100));
            } else if (discount['type'] === 'subtract') {
                discountAmmount = discount['value'];
            } else if (discount['type'] === 'pay') {
                discountAmmount = priceWithMonthDiscount - discount['value'];
            }
            discountAmmount = parseFloat(Math.ceil(parseFloat(discountAmmount) * 100) / 100);
        }

        var finalPrice = parseFloat(Math.round(((priceWithMonthDiscount - discountAmmount) * 100)) / 100).toFixed(2);
        finalPrice = parseFloat(Math.round((finalPrice / monthNumber) * 100) / 100).toFixed(2);

        return finalPrice;
    }

    planData = null

    static getPlanPrices(callback, code) {
        var url = Config.oldBackendHost + 'php/ajaxLandingPage.php?planPrices';

        if (code == null) {
            code = Cookies.get('promoCode') || null;
        }

        if (code != null) {
            url += '&promoCode=' + encodeURIComponent(code);
        }

        this.Request({
            url: url,
            method: 'get'
        }, (data) => {
            this.planData = data.data;
            callback();
        });
    }

    static isset(data, def) {
        if (typeof def !== 'undefined') {
            return this.isset(data) ? data : def;
        }
        return !(typeof data === 'undefined');
    }

    static isNumeric(str) {
        try {
            return !isNaN(str) && !isNaN(parseFloat(str)) && !isNaN(parseInt(str));
        }
        catch (e) {
            return false;
        }
    }

    static t(string, fields, components, recursive) {
        recursive = this.isset(recursive) ? recursive : true;
        components = components || [];

        if (typeof string === 'object') {
            var children = this.clone(string.props.children, false);

            string = '';
            children.map((child, key) => {
                if (typeof child === 'string') {
                    string += child;
                } else {
                    var aux = 'NA';

                    if (typeof child.props.children === 'string') {
                        aux = child.props.children;
                    }

                    string += '<' + key + '>' + aux + '</' + key + '>';
                }

                return '';
            });

            string = this.t(string, fields, components, false);

            var splitted = string.split(/<(?:|\/)[0-9]*?>/gm);

            splitted = splitted.filter((value) => value !== '');

            var componentCount = 0;
            splitted.map((string, key) => {
                var child = children[`${key}`];
                if (this.isset(child)) {
                    child = this.clone(child, false);
                    if (typeof child === 'string') {
                        child = string;
                    } else {
                        if (this.isset(components[`${componentCount}`]))
                            child = this.clone(components[`${componentCount}`], false);

                        child.props = this.clone(child.props, false);
                        child.props.children = string;

                        if (this.isNumeric(child.type)) {
                            child.type = 'font';
                        }

                        componentCount++;
                    }
                }
                children[`${key}`] = child;

                return '';
            });

            return children;
        }

        if (typeof string === 'string') {
            if (recursive && string.split(/(<(?:|\/)[0-9A-Za-z]*?>)/gm).length > 1) {
                return this.t(this.componentText(string), fields, components);
            }

            fields = fields || {};

            for (var key in this.translationKeys) {
                var translation = this.translationKeys[`${key}`];
                try {
                    var matches = string.match(translation);
                } catch {
                    matches = [];
                }
                if (matches && this.isset(matches.groups) && Object.keys(matches.groups).length > 0) {
                    fields = Object.assign(matches.groups, fields);
                    for (var id in fields) {
                        fields[`${id}`] = this.t(fields[`${id}`]);
                    }
                    string = key; break;
                }
            }
        }

        return i18n.t(string, fields);
    }

    static componentText(string, tagName, component) {
        var splitted = string.split(/(<(?:|\/)[0-9A-Za-z]*?>)/gm);
        tagName = tagName || '[0-9A-Za-z]*?';
        component = component || <></>;

        if (splitted.length > 1) {
            var object = [];
            var inTag = null;

            splitted.map((string) => {
                var tag = string.match('^<(|/)(' + tagName + ')>$');
                var key = object.length - 1;

                var obj = object[`${key}`];
                if (inTag) {
                    obj.props.children = string;
                } else if (tag) {
                    if (tag[1] === '') {
                        object.push(this.clone(component, false));
                        key++;
                        obj = object[`${key}`];
                        obj.props = this.clone(obj.props, false);
                        obj.type = tag[2];
                    } else {
                        tag = null;
                    }
                } else {
                    object.push(string);
                }

                inTag = tag;
                return null;
            });

            component = this.clone(<></>, false);
            component.props = this.clone(component.props, false);
            component.props.children = object;
        }

        var componentText = string;

        if (Utils.isset(component.props.children)) {
            componentText = component;
        }

        return componentText;
    }

    static generatePlanRegisterData(plan, frequency) {
        return 'planData=' + encodeURIComponent(btoa(JSON.stringify({ plan: plan, frequency: frequency })));
    }

    static formatString(str) {
        return str.split('-').map(function capitalize(part) {
            return part.charAt(0).toUpperCase() + part.slice(1);
        }).join(' ');
    }

    static capitalizeString(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    static setCookie(name, value, daysToLive, domain) {
        var cookie = name + '=' + encodeURIComponent(value);

        if (typeof daysToLive === 'number') {
            cookie += '; Path=/; max-age=' + (daysToLive * 24 * 60 * 60);
        }
        if (typeof domain === 'string') {
            cookie += '; domain=' + domain;
            if (typeof daysToLive !== 'number') {
                cookie += '; Path=/';
            }
        }

        document.cookie = cookie;
    }

    static redirect(url, target) {
        var url = Utils.isset(url, null);

        if (!url) {
            window.location.reload();
        } else {
            var target = target || '_self';
            window.open(url, target).focus(); // eslint-disable-line
        }
    }

    static handleGoToHash() {
        var hash = window.top.location.hash;
        var element = document.getElementById(hash.substring(1));

        if (element) {
            setTimeout(() => element.scrollIntoView({}), 500);
        }
    }


    static getCookie(name) {
        var cookieArr = document.cookie.split(';');

        for (var i = 0; i < cookieArr.length; i++) {
            var cookiePair = cookieArr[`${i}`].split('=');

            if (name === cookiePair[0].trim()) {
                return decodeURIComponent(cookiePair[1].trim());
            }
        }

        return null;
    }
}

export default Utils;
