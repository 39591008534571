import React from 'react';
import i18n from '../../../i18n';
import './NewArea.sass';

const NewArea = React.memo(props => {
    return (
        <div className={'NewTextArea ' + props.type} {...props}>
            <div className="NewText" > {i18n.t('NEW')} </div>
        </div>
    );
});

export default NewArea;
